@import url('https://fonts.google.com/specimen/Maven+Pro');

.footer {
  background: #683695;
  padding: 2em 5em;
  color: #ffffff;

  &.mobile {
    padding: 2em;
  }

  .content {
    &.mobile {
      margin-bottom: 2em;
    }

    .title {
      margin-bottom: 1em;
      span {
        font-family: "Maven Pro", sans-serif;
        font-size: 1rem;
        font-weight: bold;
        opacity: .5;
      }
    }

    .item {
      a {
        font-family: "Maven Pro", sans-serif;
        color: #ffffff;
        text-decoration: none;
        display: flex;
        font-size: .85rem;
        padding: .4em 0;

        &:hover {
          opacity: .5;
        }
      }

      .policy {
        &.mobile {
          display: none;
        }

        a {
          &.special {
            margin-top: 3em;
          }
        }

        span {
          font-family: "Maven Pro", sans-serif;
          margin-top: 1em;
          display: flex;
          font-size: .85rem;
          color: #ffffff;
        }
      }
    }
  }

  .soc-meds {
    margin-top: 13em;

    &.mobile {
      margin-top: 0;
    }

    img {
      width: 45px;
      height: 45px;
    }
  }

  .mobile-policy {
    display: none;
    &.mobile {
      display: block;
      a {
        font-family: "Maven Pro", sans-serif;
        color: #ffffff;
        text-decoration: none;
        display: flex;
        font-size: .85rem;
        padding: .4em 0;

        &:hover {
          opacity: .5;
        }

        &.special {
          margin-top: 3em;
        }
      }

      span {
        margin-top: 1em;
        display: flex;
        font-size: .85rem;
        color: #ffffff;
      }
    }
  }
}
