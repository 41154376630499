@import url('https://fonts.google.com/specimen/Maven+Pro');

.home {
  background: #191919;
  padding: 10em;
  color: #ffffff;

  &.mobile {
    padding: 2em;
  }

  .download-text {
    font-family: "Maven Pro", sans-serif;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;

    &.mobile {
      font-size: 1.5rem;
    }
  }

  .download-section {
    display: inline-flex;
    .qr-code {
      width: 105px;
      height: 105px;

      .qr-image {
        width: 100%;
        height: 100%;
      }
    }

    .or {
      font-family: "Maven Pro", sans-serif;
      margin-top: auto;
      margin-bottom: auto;

      &.mobile {
        text-align: center;
        padding: 1em 0;
      }
    }

    .download-app-link {
      &.mobile {
        text-align: center;
      }

      .download-button {
        width: 160px;
        height: 50px;
        padding: 0.15em 0;
        margin: .25em 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
