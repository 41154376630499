@import url('https://fonts.google.com/specimen/Maven+Pro');

.navbar {
  background: #683695 !important;

  .logo {
    &.mobile {
      text-align: center;
      margin-left: -1.5em;
    }

    flex-grow: 1;
    padding-top: .5em;

    .logo-image {
      height: 45px;
      width: auto;
    }
  }

  .text-navigations {
    & button {
      font-family: "Maven Pro", sans-serif;
      font-size: .8rem !important;
      color: #e2e2e2;
      transition: all 250ms ease-in-out;

      &:hover {
        font-size: .825rem !important;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
}

.drawer {
  .MuiDrawer-paper {
    background: #000000 !important;
    margin-top: 48px;

    .list {
      width: 250px;
      font-family: 'Maven Pro';
      font-size: .775rem !important;
      color: #e2e2e2;
      transition: all 250ms ease-in-out;

      &:hover {
        font-size: .8rem !important;
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
}
