@import "~video-react/styles/scss/video-react";

.shot {
  background: #191919;
  padding: 7em;
  color: #e2e2e2;

  .loading {
    height: 100vh;
    text-align: center;

    .lds-facebook {
      top: 30%;
    }
  }

  &.mobile {
    padding: 0;
    padding-bottom: 5em;

    .remove-padding-top {
      padding-top: 0 !important;
    }
  }

  .video-player {
    width: 25em;

    &.mobile {
      width: auto;
    }

    .video-react {
      height: 50em;
    }
  }

  .content {
    .profile {
      width: 25em;
      border-radius: 10px;
      background: #272727;
      color: #ffffff;
      padding: 1em 3em;

      &.mobile {
        width: auto;
        padding: 1em 2em;
      }

      .song-name {
        line-height: 1.8;
        span {
          font-size: 1.5rem;
          font-weight: bold;
        }
      }

      .artist-name {
        span {
          opacity: .5;
          font-size: .9rem;
        }
      }

      .avatar-profile {
        padding-top: 2em;
        display: inline-flex;
        .avatar {
          background: #d63ebd;
          color: #ffffff;
        }
        .name {
          padding: .5em;
          span {
            font-size: .8rem;
          }
        }
      }

      .description {
        line-height: 5;
        span {
          font-size: .9rem;
        }
      }
    }

    .scan-description {
      padding: 3em .5em;
      width: 26em;

      &.mobile {
        width: auto;
        text-align: center;
        line-height: 1.2;
      }

      span {
        font-size: .95rem;
      }
    }

    .download-section {
      display: inline-flex;
      .qr-code {
        width: 105px;
        height: 105px;

        .qr-image {
          width: 100%;
          height: 100%;
        }
      }

      .or {
        margin-top: auto;
        margin-bottom: auto;

        &.mobile {
          text-align: center;
          padding: 1em 0;
        }
      }

      .download-app-link {
        &.mobile {
          text-align: center;
        }

        .download-button {
          width: 160px;
          height: 50px;
          padding: 0.15em 0;
          margin: .25em 0;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
